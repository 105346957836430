import React from "react"

const SvgE = props => (
  <svg className='logo-e' viewBox='0 0 48.61 73.06' {...props}>
    <path fill='#8dc8e8' d='M10.63 55.12v17.94l37.98-1.21V59.86l-37.98-4.74z' />
    <path fill='#73afca' d='M0 61.82l10.63-6.7v17.94L0 70.51v-8.69z' />
    <path fill='#00608b' d='M0 44.65l10.63-16.73v17.23L0 53.34v-8.69z' />
    <path fill='#0E85B7' d='M32.34 33.98l-21.71-6.06v17.23l21.71 4.02V33.98z' />
    <path fill='#00426a' d='M48.61 17.95L10.63 0v17.23l37.98 13.33V17.95z' />
    <path fill='#00304d' d='M0 26.45L10.63 0v17.23L0 35.13v-8.68z' />
  </svg>
)

export default SvgE
