import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import IC1 from '../images/IC1.png';
import IC2 from '../images/IC2.png';
import IC3 from '../images/IC3.png';
import IC4 from '../images/IC4.png';
import { Container, Row, Col } from 'reactstrap';
import LightboxController from '../components/lightbox/controller';
import Img from 'gatsby-image';
import SvgE from '../components/icons/E.js';

const About = ({ data }) => {
    return (
        <Layout>
            <SEO
                title={`${data.wpgraphql.page.title}`}
                description={data.wpgraphql.page.acf_page_meta.metadescription}
            />
            <article>
                <header className="header--fullwidth header--lightbg">
                    {data.wpgraphql.page.featuredImage != null && (
                        <Img
                            className="header__image"
                            fluid={
                                data.wpgraphql.page.featuredImage.node.imageFile.childImageSharp
                                    .fluid
                            }
                        />
                    )}
                    <Container>
                        <Row>
                            <Col xs={{ offset: 1 }}>
                                <h1 className="heading1">
                                    Decades of
                                    <span>Multifamily Experience. </span>
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                    <SvgE />
                </header>

                <section className="section__callout">
                    <Container className="container-fluid">
                        <Row>
                            <Col
                                className="callout__text"
                                sm={{ size: 6, offset: 0 }}
                                md={{ size: 6, offset: 1, order: 2 }}
                                lg={{ size: 5 }}
                            >
                                <h3 className="heading2">
                                    {data.wpgraphql.page.acf_about.heroSubhead}
                                </h3>
                                <p className="body-copy">
                                    {data.wpgraphql.page.acf_about.heroText}
                                </p>
                                <a
                                    className="btn btn-primary"
                                    rel="noopener noreferrer"
                                    href={withPrefix(
                                        '/pdfs/IDEAL_Capital_Group_Pitch_Deck_(2023)_Long_Form.pdf'
                                    )}
                                    target="_blank"
                                >
                                    {' '}
                                    Download PDF
                                </a>
                            </Col>
                            <Col
                                className="callout__images"
                                sm={{ size: 7, offset: 1 }}
                                md={{ size: 6, offset: 0, order: 2 }}
                                lg={{ size: 5 }}
                            >
                                {data.wpgraphql.page.acf_about.topImageBack != null && (
                                    <Img
                                        className="callout__back-image w-100 d-none d-xl-block"
                                        fixed={
                                            data.wpgraphql.page.acf_about.topImageBack.imageFile
                                                .childImageSharp.fixed
                                        }
                                        alt={data.wpgraphql.page.acf_about.topImageBack.altText}
                                    />
                                )}
                                {data.wpgraphql.page.acf_about.topImageFront != null && (
                                    <Img
                                        className="callout__front-image mt-5"
                                        fixed={
                                            data.wpgraphql.page.acf_about.topImageFront.imageFile
                                                .childImageSharp.fixed
                                        }
                                        alt={data.wpgraphql.page.acf_about.topImageBack.altText}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Container>
                    <Row>
                        <Col xs={{ offset: 1 }} md={{ offset: 1 }}>
                            <h2 className="heading2">Our Team</h2>
                        </Col>
                    </Row>
                </Container>

                <LightboxController />

                <section className="section__giving-back section__featured-properties">
                    <Container>
                        <Row className="mb-5">
                            <Col
                                className="callout__text"
                                sm={{ size: 7, offset: 1 }}
                                md={{ size: 6 }}
                                lg={{ size: 5 }}
                            >
                                <h2 className="heading2">
                                    {data.wpgraphql.page.acf_about.section1.head}
                                </h2>
                                {data.wpgraphql.page.acf_about.section1.text
                                    .split('<br/><br/>')
                                    .map((text, i) => {
                                        return (
                                            <p key={i} className="body-copy">
                                                {text}
                                            </p>
                                        );
                                    })}
                                <h4 className="blockquote">
                                    {data.wpgraphql.page.acf_about.section1.subhead}
                                </h4>
                            </Col>
                            <Col
                                className="callout__text callout__text--image-col"
                                sm={{ size: 6, offset: 0 }}
                                md={{ size: 6, offset: 1 }}
                                lg={{ size: 5, offset: 0 }}
                            >
                                {data.wpgraphql.page.acf_about.section1.image != null && (
                                    <>
                                        <Img
                                            fluid={
                                                data.wpgraphql.page.acf_about.section1.image
                                                    .imageFile.childImageSharp.fluid
                                            }
                                            alt={
                                                data.wpgraphql.page.acf_about.section1.image.altText
                                            }
                                        />
                                        <Img
                                            fluid={
                                                data.wpgraphql.page.acf_about.section1.secondImage
                                                    .imageFile.childImageSharp.fluid
                                            }
                                            alt={
                                                data.wpgraphql.page.acf_about.section1.secondImage
                                                    .altText
                                            }
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="organizations-we-support">
                    <Container>
                        <div className="section__cali-centric">
                            <Row>
                                <Col
                                    xs={{ size: 12, offset: 1 }}
                                    md={{ size: 7, offset: 1 }}
                                    lg={{ size: 6, offset: 1 }}
                                >
                                    <h2 className="heading2">
                                        Some of the Organizations We Support Include
                                    </h2>
                                </Col>
                            </Row>
                            <ul class="image-list">
                                <div class="row">
                                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 3, offset: 1 }}>
                                        <li class="image-list__item">
                                            <img src={IC1} />
                                        </li>
                                    </Col>
                                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 3, offset: 0 }}>
                                        <li class="image-list__item">
                                            <img src={IC2} />
                                        </li>
                                    </Col>
                                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 3, offset: 0 }}>
                                        <li class="image-list__item">
                                            <img src={IC3} />
                                        </li>
                                    </Col>
                                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 3, offset: 0 }}>
                                        <li class="image-list__item">
                                            <img src={IC4} />
                                        </li>
                                    </Col>
                                </div>
                            </ul>
                        </div>
                    </Container>
                </section>
            </article>
        </Layout>
    );
};

export default About;

export const query = graphql`
    query {
        allFile(filter: { extension: { eq: "pdf" } }) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }
        wpgraphql {
            page(id: "cGFnZToxNA==") {
                title
                acf_page_meta {
                    metadescription
                }
                acf_about {
                    heroHead
                    heroSubhead
                    heroText
                    topImageFront {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fixed(width: 536, height: 462, quality: 90) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    topImageBack {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fixed(width: 642, height: 428, quality: 90) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    section1 {
                        head
                        subhead
                        text
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 616, quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        secondImage {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 616, quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
                featuredImage {
                    node {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
